import React, {useLayoutEffect,useRef, useState, useEffect} from "react";
import techLineFormStyles from "../../../styles/userArea/stepperTechLineFormStyles/TechLineFormStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import LoadingComponent from "../../../components/commonComponents/userManagementComponents/LoadingComponent";
import "aos/dist/aos.css";
import aos from "aos";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import SearchInput from "../../../components/commonComponents/utilComponents/SearchInput";

function TechLineFormView(props) {
    const classes = techLineFormStyles();
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const selectedRef = useRef(null);
    
    // Modifica la función que maneja los cambios en los RadioButtons
    const handleRadioChange = (techLineId, knowledgeAreaId) => {
        setSelectedItemId(techLineId);
        setExpandedAccordion(knowledgeAreaId); // Asegura que el Accordion se mantenga abierto
      };

    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            if (selectedRef.current) {
                selectedRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        });
    }, [selectedItemId]); // Cambiado de props.selectedItem a selectedItemId
    

    useEffect(() => {
        aos.init({ duration: 1000 });
    
        if (props.selectedItem) {
            setExpandedAccordion(
                props.knowledgeAreas.find(area =>
                    area.techLines.some(techLine => techLine.name === props.selectedItem)
                )?.id || null
            );            
    
            const selectedTechLine = props.knowledgeAreas
                .flatMap(area => area.techLines)
                .find(techLine => techLine.name === props.selectedItem
                
            );
    
            if (selectedTechLine) {
                setSelectedItemId(selectedTechLine.id); // Asegura que el radio seleccionado se mantenga
            }
            
        } 
    }, [props.selectedItem, props.knowledgeAreas]);
    
    return (

        <div style={{overflowX: "hidden"}}>

            <Grid Container
                  spacing={0}
                  justify={"center"}
                  alignItems={"center"}
                  styles={{overflowX: "hidden"}}
            >
                <Grid item xs={12}><h3 className={classes.legendText} component="legend" style={{margin: '35px'}}>Líneas
                    tecnologicas agrupadas por áreas de conocimiento</h3>
                </Grid>
                {props.loading ?
                    <div className={classes.loadingComponent}>
                        <LoadingComponent color={"primary"}/>
                    </div>
                    : <Grid Container
                            spacing={0}
                            justify={"center"}
                            alignItems={"center"}
                            styles={{overflowX: "hidden"}}
                    >
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: '35px'
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={props.register}
                                        name="techLineApplicant"
                                        color="primary"
                                        onChange={(e) => props.handleCheckTechLineApplicant(e)}
                                        icon={<RadioButtonUncheckedOutlinedIcon/>}
                                        checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                                        checked={props.techLineApplicant}
                                    />
                                }
                                name="techLineApplicant"
                                label="No dispongo de esta línea pero me gustaría incorporarla"
                            />
                        </Grid>
                        <Grid item xs={12} data-aos="zoom-in">
                            <SearchInput
                                collectionToFilter={props.knowledgeAreas}
                                collectionSetter={props.setKnowledgeAreas}
                                setLoading={props.setLoading}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {props.loading ?
                <div className={classes.loadingComponent}>
                    <LoadingComponent color={"primary"}/>
                </div>
                :
                <form component="fieldset" onSubmit={props.handleSubmit(props.customSubmit)}>
                    <div className={classes.techLinesContainer}>
                        <Controller
                            data-aos={"fade-right"}
                            defaultValue={props.selectedItem}
                            as={
                                <RadioGroup aria-label="selected"
                                            aria-required={true} required
                                            value={props.value}
                                            onChange={props.handleChange}
                                >
                                    <Grid container direction="column" className={classes.root} item xs={8}>
                                        {
                                            props.knowledgeAreas.length > 0 ?

                                                <Grid container direction="column" item xs={12}>
                                                    {props.knowledgeAreas.map(knowledgeArea =>
                                                        <div key={knowledgeArea.id}>
                                                            {/* <Accordion key={knowledgeArea.id}> */}

                                                            <Accordion 
                                                                key={knowledgeArea.id} 
                                                                expanded={expandedAccordion === knowledgeArea.id}
                                                                onChange={(event, isExpanded) => setExpandedAccordion(isExpanded ? knowledgeArea.id : null)}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon/>}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography component={'div'}
                                                                                className={classes.heading}>{knowledgeArea.name}
                                                                    </Typography>
                                                                </AccordionSummary>

                                                                <AccordionDetails>
                                                                    <Grid container direction="column"
                                                                          item
                                                                          xs>
                                                                        {
                                                                            knowledgeArea.techLines.map(
                                                                                techLine =>
                                                                                    <FormControlLabel
                                                                                        key={techLine.id}
                                                                                        ref={el => {
                                                                                            if (selectedItemId === techLine.id) {
                                                                                                selectedRef.current = el;
                                                                                            }
                                                                                        }}
                                                                                        control=
                                                                                            {
                                                                                                <Radio
                                                                                                    inputRef={props.register}
                                                                                                    name={techLine.id.toString()}
                                                                                                    value={techLine.name}
                                                                                                    checked={selectedItemId === techLine.id}
                                                                                                    onChange={() => handleRadioChange(techLine.id, knowledgeArea.id)}
                                                                                                    color={selectedItemId === techLine.id ? "primary" : "default"}
                                                                                                />
                                                                                            }
                                                                                        label={techLine.name}
                                                                                    />
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <div>&nbsp;</div>
                                                        </div>
                                                    )}
                                                </Grid>
                                                :
                                                <div className={classes.loadingComponent}>
                                                    <h5 className={classes.textNoResults}> No hay resultados</h5>
                                                </div>
                                        }
                                        <div>&nbsp;</div>
                                    </Grid>
                                </RadioGroup>
                            }
                            name="selected"
                            control={props.control}>
                        </Controller>
                        {
                            props.errors.selected &&
                            <FormLabel className={classes.warmText}
                                       color='secondary'> Debes
                                seleccionar una línea tecnológica
                            </FormLabel>
                        }
                    </div>
                    <hr className={classes.hrForm}></hr>
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            className={classes.saveButton}
                        >
                            Siguiente
                        </Button>
                    </div>
                </form>
            }
        </div>
    );
}

export default TechLineFormView;