import React, {useContext, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Column} from "../../../components/adminArea/matchesPanelComponents/Column";
import {DragDropContext} from "react-beautiful-dnd";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";
import {ModalAddMatchesToKanban} from "../../../components/adminArea/matchesPanelComponents/ModalAddMatchesToKanban";
import {Controller} from "react-hook-form";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {CircularProgress} from "@material-ui/core";
import {TitleMatchDetails} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";

export const MatchesPanelView = (props) => {
    const [selectedCompany, setSelectedCompany] = useState(null);

    return (
        <DragDropContext
            onDragEnd={props.onDragEndHandler}
        >

            <Grid item xs={12} container spacing={0} justify={"center"} align={"center"}>
                <TitleMatchDetails>Buscador de compañías por nombre</TitleMatchDetails>
            </Grid>
            {props.companyNames.length > 0 ? (
                <Grid item xs={12} justify={"center"} align={"center"}>
                    <Controller
                        render={({onChange, ...args}) => (
                            <Autocomplete
                                value={selectedCompany || null}
                                options={props.companyNames}
                                getOptionLabel={(option) => option.name}
                                // groupBy={option => option.town}
                                renderInput={(params) => <TextField {...params}
                                                                    label="Selecciona una empresa"
                                                                    margin="normal"/>}
                                onChange={(e, selectedCompany) => {

                                    setSelectedCompany(selectedCompany);
                                    if(selectedCompany && selectedCompany.name)
                                    {
                                        onChange(selectedCompany)
                                        props.handleCompanySelectionChange(selectedCompany.name)
                                    }
                                    else{                                        
                                        props.handleCompanySelectionChange("");
                                    }
                                    

                                }}
                                style={{maxWidth: '800px', marginBottom: '80px'}}
                                {...args}
                            />
                        )}
                        name={"companySearch"}
                        control={props.control}
                    />

                </Grid> ): (<Grid item xs={12} style={{minHeight: '500px'}}><CircularProgress/></Grid>
            )}


            <Grid container spacing={0} justify={"center"} align={"center"}>
                {props.columns.columnOrder.map((columnId) => {
                    return (
                        <Grid item xs={12} md={6} lg={3} key={columnId}>
                            <Column
                                columnId={columnId}
                                modalRef={props.modalRef}
                                onAddNewCardsToKanban={props.onAddNewCardsToKanban}
                                {...props}
                            >
                            </Column>
                            <Grid item xs={12}>
                                {
                                    <ModalAddMatchesToKanban
                                        modalRef={props.modalRef}
                                        columnId={columnId}
                                        columns={props.columns}
                                        loading={props.loading}
                                        setLoading={props.setLoading}
                                        closeModal={props.closeModal}
                                        openModal={props.onAddNewCardsToKanban}
                                    />
                                }
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </DragDropContext>
    )
}