import React, { useContext, useEffect, useState } from 'react';
import TechLineForm from "./TechLineForm";
import TechLineExperienceForm from "./TechLineExperienceForm";
import StepperFormView from "../../../views/userArea/stepperViews/StepperFormView";
import { useHistory } from 'react-router-dom';
import { CompanyContext } from '../../../context/CompanyContext';
import theme from "../../../Theme";
import { ThemeProvider } from "@material-ui/styles";
import TechLineCollaborationForm from "./TechLineCollaborationForm";
import axios from "axios";
import TrackEvent from "../../../tracking/EventTracking";

export default function StepperTechLine(props) {
    function getSteps() {
        return ['Líneas tecnológicas', 'Experiencia', 'Colaboración'];
    }

    const [company, setCompany] = useContext(CompanyContext);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [techLineForm, setTechLineForm] = useState(
        props.location.state.techLine || JSON.parse(localStorage.getItem("workersForm")) || {}
    );
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
    }, [techLineForm]);

    const handleBack = () => {
        if (activeStep === 0) {
            localStorage.removeItem("techLineForm");
            localStorage.removeItem("workersForm");
            localStorage.removeItem("experienceForm");
            history.push("company");
        } else {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const handleReset = async () => {
        setLoading(true);
        let techLineCompany = {
            id: techLineForm.generalData.id,
            techLineId: techLineForm.generalData.techLineId,
            companyId: techLineForm.generalData.companyId,
            name: techLineForm.generalData.name,

            workersNum: techLineForm.workersData?.workersAssigned ?? 0,
            highValueWorkersNum: techLineForm.workersData?.workersHighValue ?? 0,
            wantToStartSoonCollaboration: techLineForm.workersData.wantToStartSoonCollaboration,
            timeToStartCollaboration: techLineForm.workersData.timeToStartCollaboration,
            collaborationWillingness: techLineForm.workersData.collaborationWillingness,
            collaborationWillingnessPreferenceTechLine: techLineForm.workersData.collaborationWillingnessPreference,

            yearsExperience: techLineForm.experienceData.yearsExperience,
            techFrontierPosition: techLineForm.experienceData.techFrontierPosition,
            techLineApplicant: techLineForm.generalData.techLineApplicant
        };

        try {
            let response;
            if (techLineForm.generalData.id !== undefined) {
                response = await axios.put("/techlinescompany", techLineCompany);
            } else {
                response = await axios.post("/techlinescompany", techLineCompany);
                TrackEvent("store_tech_line", "Guarda formulario línea tecnológica", "store_tech_line");
            }


            // 🔄 Actualiza el estado con los datos nuevos del backend
            setTechLineForm(prevState => ({
                ...prevState,
                ...response.data
            }));
            

        } catch (error) {
            console.error("❌ Error al guardar en el backend:", error);
            if (error.response?.status === 403) {
                history.push("/forbidden");
            }
        }

        localStorage.removeItem("techLineForm");
        localStorage.removeItem("experienceForm");
        localStorage.removeItem("workersForm");

        setLoading(false);
        history.push('company');
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <TechLineForm techLineFormContext={techLineForm}
                                     setTechLineFormContext={setTechLineForm}
                                     company={company}
                                     activeStep={activeStep}
                                     handleBack={handleBack}
                                     handleNext={handleNext}
                                     steps={steps} />;
            case 1:
                return <TechLineExperienceForm techLineFormContext={techLineForm}
                                               setTechLineFormContext={setTechLineForm}
                                               company={company}
                                               activeStep={activeStep}
                                               handleBack={handleBack}
                                               handleNext={handleNext}
                                               steps={steps} />;
            case 2:
                return <TechLineCollaborationForm techLineFormContext={techLineForm}
                                                  setTechLineFormContext={setTechLineForm}
                                                  company={company}
                                                  activeStep={activeStep}
                                                  handleBack={handleBack}
                                                  handleNext={handleNext}
                                                  steps={steps} />;
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <StepperFormView steps={steps}
                             activeStep={activeStep}
                             handleReset={handleReset}
                             getStepContent={getStepContent}
                             handleBack={handleBack}
                             handleNext={handleNext}
                             loading={loading}
            />
        </ThemeProvider>
    );
}
