import React, { useEffect } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import aos from "aos";
import { makeStyles } from '@material-ui/core/styles';
import { entityTypeMapping } from "../../../constants/mappings";

// Estilos personalizados
const useStyles = makeStyles((theme) => ({
    radioChecked: {
        '&.Mui-checked': {
            color: theme.palette.primary.main, // Cambia el color de selección al color primario de tu tema
        },
    },
}));

export default function CompanyTypeRadioButtonsView(props) {
    const classes = useStyles(); // Inicializar estilos personalizados

    useEffect(() => {
        aos.init({ duration: 2000 });
    }, []);

    // Buscar la clave de la entidad seleccionada (la que tiene valor `true`)
    const selectedEntityType = Object.keys(props.defaultValue).find(key => props.defaultValue[key] === true);

    return (
        <Grid item xs={12} align={"left"}>
            <FormControl control={props.control} label={''}>
                <RadioGroup
                    name={props.radioGroupName}
                    value={selectedEntityType} // Establecer el valor seleccionado basado en el `defaultValue`
                    onChange={props.handleChangeRadioButton}
                >
                    {Object.keys(entityTypeMapping).map((companyTypeId) => (
                        <Grid item xs={12} key={companyTypeId}>
                            <FormControlLabel
                                inputRef={props.register}
                                value={companyTypeId}
                                control={
                                    <Radio
                                        className={selectedEntityType === companyTypeId ? classes.radioChecked : ''}
                                        color={"primary"}
                                    />
                                }
                                label={entityTypeMapping[companyTypeId]} // Etiqueta del tipo de empresa
                            />
                        </Grid>
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
}

