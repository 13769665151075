import React, {useEffect, useState} from "react";
import TechLineExperienceFormView from "../../../views/userArea/stepperTechLineFormViews/TechLineExperienceFormView";
import * as yup from "yup";
import axios from "axios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";

function TechLineExperienceForm(props) {
    let experienceChosenStored = JSON.parse(localStorage.getItem("experienceForm"));
    let receivedForm = props.techLineFormContext.experienceData
    let techLineForm = receivedForm ? receivedForm : experienceChosenStored;

    
    const [selectedItemId, setSelectedItemId] = useState(props.techLineFormContext.generalData.id || "");
    const [loading, setLoading] = useState(true);
    const [yearsExperience, setYearsExperience] = useState(techLineForm ? techLineForm.yearsExperience : "");
    const [techFrontierPosition, setTechFrontierPosition] = useState(techLineForm ? techLineForm.techFrontierPosition : 0);

    let validationSchema = yup.object().shape({
        yearsExperience: yup.number(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);  // Iniciar carga
                // Llamar a las funciones para obtener los datos desde la API
                await Promise.all([getYearsExperience(), getTechFrontierPosition()]);
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            } finally {
                setLoading(false);  // Detener la carga una vez terminada
            }
        };

        if (selectedItemId) {
            fetchData();
        }
    }, [selectedItemId]);

    useEffect(() => {
        if (!loading && yearsExperience !== "" && techFrontierPosition !== 0) {
            setYearsExperience(yearsExperience);  // Establece el valor de yearsExperience en el formulario
            setTechFrontierPosition(techFrontierPosition);  // Establece el valor de techFrontierPosition en el formulario
            setLoading(false);  // Detenemos el estado de carga
        }
    }, [yearsExperience, techFrontierPosition]);

    function customSubmit(data) {

        let experienceData = {
            yearsExperience: data.yearsExperience,
            techFrontierPosition: data.techFrontierPosition
        }
        let techLineFormContext = {...props.techLineFormContext, experienceData};
        props.setTechLineFormContext(techLineFormContext);
        localStorage.setItem("experienceForm", JSON.stringify(experienceData));
        setYearsExperience(experienceData.yearsExperience);
        setTechFrontierPosition(data.techFrontierPosition);
        props.handleNext();
    }

    const getYearsExperience = async () => {
        try {
            setLoading(true);  // Iniciar la carga
    
            const url = '/techlinescompany/yearsExperience/' + props.techLineFormContext.generalData.id;
            const response = await axios({
                method: 'GET',
                url,
                data: {},
            });
            setYearsExperience(response.data);  // Guarda los años de experiencia
        } catch (error) {
            console.error("Error al obtener los años de experiencia:", error);
            setLoading(false);  // Detener la carga en caso de error
        }
    };
    
    const getTechFrontierPosition = async () => {
        try {
            setLoading(true);  // Iniciar la carga
            
            const url = '/techlinescompany/techFrontierPosition/' + props.techLineFormContext.generalData.id;
            const response = await axios({
                method: 'GET',
                url,
                data: {},
            });
    
            setTechFrontierPosition(response.data);  // Guarda la posición de la frontera tecnológica
        } catch (error) {
            console.error("Error al obtener la posición de la frontera tecnológica:", error);
            setLoading(false);  // Detener la carga en caso de error
        }
    };
    


    return (
        <ThemeProvider theme={theme}>
            {
                <TechLineExperienceFormView handleSubmit={handleSubmit} control={control} register={register}
                                            customSubmit={customSubmit}
                                            errors={errors}
                                            stepperProps={props}
                                            yearsExperience={yearsExperience}
                                            techFrontierPosition={techFrontierPosition}
                />}
        </ThemeProvider>
    );
}

export default TechLineExperienceForm;