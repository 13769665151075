import React, {useEffect, useState} from "react";
import {MatchesDetailsScreenView} from "../../../views/adminArea/matchesDetailsViews/MatchesDetailsScreenView";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {getDataForCSV} from '../../../services/FileDownloadService'

export const MatchesDetailsScreen = () => {
    const [matches, setMatches] = useState();
    const [matchesByTypeData, setMatchesByTypeData] = useState();
    const [matchesManagedAndAchieved, setMatchesManagedAndAchieved] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getMatchesDetails();
        getMatchesByTypeChartData();
        getMatchesManagedAchivedChartData();
    }, [])

    async function deletePosibleMatch(id) {
        const url = "matches/delete/" + id;

        await axios({
            method: 'DELETE',
            url,
            data: {}
        })
            .then(async res => {
                //window.location.reload(false);
                reloadMatches();
            }, error => {
                if (error.response.status === 506) {

                    Alert.fire({
                        title: "Ups...",
                        text: 'El match a borrar no existe.',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    });

                } 
            })
            .catch((error) => {});
    }

    async function reloadMatches() {
        getMatchesDetails();
        getMatchesByTypeChartData();
        getMatchesManagedAchivedChartData();
    }

    async function getMatchesDetails() {
        const url = "matches"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let originalMatches = res.data;
                
                let modifiedMatches = originalMatches.map(element => {
                    return {
                        ...element,
                        deletePosibleMatch: deletePosibleMatch,
                        reloadMatches: reloadMatches
                    }
                });

                setMatches(modifiedMatches);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getMatchesByTypeChartData() {
        const url = "/admin/charts/matchesbytype"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let dataFormated = formatMatchesByTypeData(res.data)
                setMatchesByTypeData(dataFormated);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los datos de matches por tipo, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getMatchesManagedAchivedChartData() {
        const url = "/admin/charts/matchesmanagedandachieved"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let dataFormated = formatMatchesManagedAndAchievedChartData(res.data)
                setMatchesManagedAndAchieved(dataFormated);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los datos de matches gestionados, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    function formatMatchesManagedAndAchievedChartData(matchesManagedAndAchievedData) {
        return [
            {
                name: "matches",
                Total: matchesManagedAndAchievedData.numberOfMatchesTotal,
                Gestionados: matchesManagedAndAchievedData.numberOfMatchesManaged,
                Satisfactorios: matchesManagedAndAchievedData.numberOfMatchesAchieved,
                Fallidos: matchesManagedAndAchievedData.numberOfMatchesUnAchieved
            }
        ]

    }

    function formatMatchesByTypeData(numberOfMatchesByType) {
        return [
            {
                name: "Línea",
                value: numberOfMatchesByType.numberOfMatchesTechLine
            },
            {
                name: "Colaboración y predisposición",
                value: numberOfMatchesByType.numberOfMatchesCollaborationAndPredisposition
            }
        ]

    }

    async function handleCSVDownload() {
        let dataType = "match"
        await getDataForCSV(dataType)
    }

    return (
        <MatchesDetailsScreenView
            matches={matches}
            loading={loading}
            matchesByTypeData={matchesByTypeData}
            matchesManagedAndAchieved={matchesManagedAndAchieved}
            handleCSVDownload={handleCSVDownload}
        />)
}