import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import { timeToStartMapping, entityTypeMapping } from "../../../constants/mappings";
import TechLineViewStyle from "../../../styles/userArea/companyCardComponentsStyles/TechLineStyles"
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";


export default function TechLineView(props) {
    const classes = TechLineViewStyle();

    return (
        <div className={classes.root}>
            <div className={classes.pTechLineSeparator}>&nbsp;</div>
            <Accordion key={props.techLine.id}
                // defaultExpanded={props.numberOfTechline === 0}
            >
                <AccordionSummary
                    expandIcon={
                        <div>
                            <ExpandMoreIcon/>
                        </div>}

                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={classes.buttonGroup}>
                        <IconButton className={classes.editButton} variant="contained"
                                    onClick={(event) => props.handleEditButton(event, props.techLine)}>
                            <EditIcon color={"primary"}/>
                        </IconButton>

                        <IconButton className={classes.deleteButton} aria-label="delete"
                                    onClick={(event) => props.deleteTechLine(event, props.techLine.id)}
                        >
                            <HighlightOffIcon/>
                        </IconButton>
                    </div>
                    <div className={classes.accordionSummaryNameContainer}>
                        <Typography component={'div'} className={classes.accordionSummaryName}>
                            {props.techLine.name}
                        </Typography>
                    </div>
                </AccordionSummary>

                
                {/* Aquí agregamos los detalles expandibles */}
                <AccordionDetails>
                    <div className={classes.detailsContainer}>
                        <Typography variant="h6">Experiencia en la linea tecnológica</Typography>
                        <Typography>Años de experiencia: {props.techLine?.yearsExperience ?? "No disponible"}</Typography>
                        <Typography>Posición en la frontera tecnológica: {props.techLine?.techFrontierPosition ?? "No disponible"}</Typography>

                        <Typography variant="h6">Colaboracion</Typography>
                        <Typography>
                            Tiempo estimado para iniciar colaboración:{" "}
                            {timeToStartMapping[props.techLine?.timeToStartCollaboration] ?? "No disponible"}
                        </Typography>
                        <Typography>Valora predisposicón entidad colaboradora: {props.techLine?.collaborationWillingness ?? "No disponible"}</Typography>
                        <Typography>
                            Preferencia de colaboración:{" "}
                            {props.techLine?.collaborationWillingnessPreferenceTechLine
                                ? Object.entries(props.techLine.collaborationWillingnessPreferenceTechLine)
                                    .filter(([key, value]) => value && key !== "id")
                                    .map(([key]) => entityTypeMapping[key] || key.replace(/([A-Z])/g, " $1")) // Usa el mapeo o formatea camelCase
                                    .join(", ") || "No disponible"
                                : "No disponible"}
                        </Typography>

                    </div>
                </AccordionDetails>

                <br></br>
            </Accordion>
        </div>
    );
}