import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import { ThemeProvider } from "@material-ui/styles";
import TechLineCollaborationFormView from "../../../views/userArea/stepperTechLineFormViews/TechLineCollaborationFormView";
import { allCompanyTypes } from "../StepperComponents/companyTypes"; // Importación correcta


function TechLineCollaborationForm(props) {
    const history = useHistory();
    const location = useLocation();

    // Obtener datos desde location.state o localStorage
    let storedData = JSON.parse(localStorage.getItem("workersForm")) || {};
    let receivedForm = location.state?.techLine?.workersData;
    let techLineForm = receivedForm || storedData;

    // Estados locales con valores iniciales
    const [wantToStartSoonCollaboration, setWantToStartSoonCollaboration] = useState(techLineForm.wantToStartSoonCollaboration ?? true);
    const [timeToStartCollaboration, setTimeToStartCollaboration] = useState(techLineForm.timeToStartCollaboration ?? "threeOrLessMonths");
    const [collaborationWillingness, setCollaborationWillingness] = useState(techLineForm.collaborationWillingness ?? 4);
    const [collaborationWillingnessPreference, setCollaborationWillingnessPreference] = useState(techLineForm.collaborationWillingnessPreference ?? "tractingCompany");

    // Esquema de validación
    const validationSchema = yup.object().shape({
        collaborationWillingness: yup.number().required(),
    });

    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Función para reiniciar el mapa de valores booleanos
    function setAllCompanyTypestoFalse(map) {
        for (let key of map.keys()) {
            map.set(key, false);
        }
    }

    // Función para convertir Map a objeto JSON
    function getObjectFromMap() {
        let objectFromMap = {};
        for (let [key, value] of allCompanyTypes) {
            objectFromMap = { ...objectFromMap, [key]: value };
        }
        return objectFromMap;
    }

    function customSubmit(data) {
        console.log("📢 Valor recibido en customSubmit:", data.collaborationWillingnessPreference);
        
        setAllCompanyTypestoFalse(allCompanyTypes);
        allCompanyTypes.set(data.collaborationWillingnessPreference, true);
        let objectFromMap = getObjectFromMap(allCompanyTypes);

        console.log("✅ Objeto actualizado antes de guardar:", objectFromMap);

        let workersData = {
            wantToStartSoonCollaboration: data.wantToStartSoonCollaboration,
            timeToStartCollaboration: data.timeToStartCollaboration,
            collaborationWillingness: data.collaborationWillingness,
            collaborationWillingnessPreference: objectFromMap, // Ahora es un objeto con valores booleanos
        };

        console.log("🛠 workersData final:", workersData);

        let techLineFormContext = { ...props.techLineFormContext, workersData };
        props.setTechLineFormContext(techLineFormContext);

        localStorage.setItem("workersForm", JSON.stringify(workersData));

        // Actualizar estados locales
        setWantToStartSoonCollaboration(workersData.wantToStartSoonCollaboration);
        setTimeToStartCollaboration(workersData.timeToStartCollaboration);
        setCollaborationWillingness(workersData.collaborationWillingness);
        setCollaborationWillingnessPreference(workersData.collaborationWillingnessPreference);

        props.handleNext();
    }

    return (
        <ThemeProvider theme={theme}>
            <TechLineCollaborationFormView
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                customSubmit={customSubmit}
                errors={errors}
                stepperProps={props}
                wantToStartSoonCollaboration={wantToStartSoonCollaboration}
                setWantToStartSoonCollaboration={setWantToStartSoonCollaboration}
                handleChangeCheckWantToStartSoon={(e) => setWantToStartSoonCollaboration(e.target.checked)}
                timeToStartCollaboration={timeToStartCollaboration}
                handleChangetimeToStart={(e) => setTimeToStartCollaboration(e.target.value)}
                setCollaborationWillingness={setCollaborationWillingness}
                setCollaborationWillingnessPreference={setCollaborationWillingnessPreference}
                handleChangeCollaborationWillingNessPreference={(e) => setCollaborationWillingnessPreference(e.target.value)}
                collaborationWillingnessPreference={collaborationWillingnessPreference}
                collaborationWillingness={collaborationWillingness}
            />
        </ThemeProvider>
    );
}

export default TechLineCollaborationForm;