import React, {useEffect, useContext, useRef, useState} from "react";
import axios from "axios";
import Alert from "sweetalert2";
import {MatchesPanelView} from "../../../views/adminArea/matchesPanelViews/MatchesPanelView";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";
import {colorPrimary} from "../../../EnvironmentVars";
import {useForm} from "react-hook-form";

export const MatchesPanel = () => {

    const {matches = [], columns = {}, filterMatchesByCompany, getMatchesInKanban } = useContext(MatchesDnDContext);
    const {updateMatchColumnState, updateMatchIndexState, updateMatchDataInBack} = useContext(MatchesDnDContext);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyNames, setCompanyNames] = useState([]);
    const modalRef = useRef();
    const { control } = useForm({
        defaultValues: {
            companySearch: null
        }
    });

    console.log(matches); // Verifica si matches tiene datos
    console.log(companyNames); // Verifica el resultado

    
    useEffect(() => {
        if (matches.length > 0 && companyNames.length === 0) {
            console.log("Cargando compañías únicas por primera vez...");
    
            const uniqueCompanies = Array.from(
                new Map(
                    matches
                        .flatMap(match => [match?.companyOne, match?.companyTwo])
                        .filter(Boolean) // Elimina valores nulos o undefined
                        .map(company => [company.id, company]) // Usa un Map para eliminar duplicados por ID
                ).values()
            );
    
            setCompanyNames(uniqueCompanies);
            console.log("Compañías cargadas:", uniqueCompanies);
        }
    }, [matches]);

    /*
    useEffect(() => {
        if (matches.length > 0 && companyNames.length === 0) {
            console.log("Cargando compañías únicas por primera vez...");
        
            const uniqueCompanies = Array.from(
                new Map(
                    matches
                        .flatMap(match => [match?.companyOne, match?.companyTwo])
                        .filter(Boolean) // Elimina valores nulos o undefined
                        .map(company => [company.id, company]) // Usa un Map para eliminar duplicados por ID
                ).values()
            );
            
            // Crear una compañía ficticia con name como null
            const companyWithNullName = { id: 'prueba', name: 'Selecciona empresa' };
    
            // Insertar la compañía ficticia en la primera posición
            uniqueCompanies.unshift(companyWithNullName);
    
            // Actualizar el estado con la lista de compañías únicas
            setCompanyNames(uniqueCompanies);
            console.log("Compañías cargadas:", uniqueCompanies);
        }
    }, [matches]);*/
    
    const onDragEndHandler = result => {
        const {destination, source, reason} = result;
        if (reason === "DROP") {
            if (!destination) {
                return;
            }

            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            const column = columns[destination.droppableId];
            const oldColumn = columns[source.droppableId];
            const matchId = result.draggableId
            if (destination.droppableId !== source.droppableId) {
                updateMatchColumnState(matchId, oldColumn, column, source.index, destination.index);
            } else if (destination.droppableId === source.droppableId && destination.index !== source.index) {
                updateMatchIndexState(matchId, source.index, destination.index, column.columnType);
            }
            updateMatchDataInBack();
        }
    }



    async function getMatchesByCompanyName(company) {
        console.log(company)
        const url = "matches/filter/" + encodeURIComponent(company); 
        let results = [];
        await axios({
            method: 'GET',
            url, 
            data: {}, 
        })
        .then(res => {
            console.log("Matches filtrados:", res.data);
            results = res.data;
            filterMatchesByCompany(res.data);             
            setLoading(false);
        })
        .catch(error => {
            console.error("Error al obtener los matches filtrados:", error);
            Alert.fire({
                title: "Ups...",
                text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                icon: 'warning',
                timer: 2500,
                showConfirmButton: false,
                iconColor: colorPrimary
            });
            filterMatchesByCompany(matches);
            setLoading(false);
        });
    }
    

    async function handleCompanySelectionChange(selectedCompany) {
        setLoading(true); // Indicamos que estamos cargando
        console.log("selectedCompany:", selectedCompany);

        if (!selectedCompany || selectedCompany === "") {
            getMatchesInKanban(); // Si no hay selección, mostramos todos los matches
            setLoading(false);
        } else {
            // Llamamos a la función para obtener los matches filtrados por la empresa
            getMatchesByCompanyName(selectedCompany);
            setLoading(false);
        }
    }


    const onAddNewCardsToKanban = () => {
        modalRef.current.open();
    }
    const closeModal = () => {
        modalRef.current.close();
        modalRef.current.destroy()
    }

    return (
        <div>
            <MatchesPanelView
                matches={matches}
                columns={columns}
                onAddNewCardsToKanban={onAddNewCardsToKanban}
                closeModal={closeModal}
                onDragEndHandler={onDragEndHandler}
                loading={loading}
                setLoading={setLoading}
                modalRef={modalRef}
                companyNames={companyNames}
                control={control}
                handleCompanySelectionChange={handleCompanySelectionChange}
            />
        </div>
    )
}