import React, {useEffect} from "react";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import techLineExperienceFormStyles
    from "../../../styles/userArea/stepperTechLineFormStyles/TechLineExperienceFormStyles";
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import aos from "aos";
import "aos/dist/aos.css"
import {Controller} from "react-hook-form";
import {Slider, Typography} from "@material-ui/core";

const marksTechFrontierPosition = [
    {
        value: -3,
        label: 'Debilidad',
    },
    {
        value: 3,
        label: 'Fortaleza',
    }
];


function TechLineExperienceFormView(props) {
    const classes = techLineExperienceFormStyles();

    useEffect(() => {
        aos.init({ duration: 1000 });

        // Aseguramos que setValue se llama solo cuando tenemos un valor válido
        if (props.yearsExperience !== undefined && props.yearsExperience !== null) {
            props.control.setValue("yearsExperience", props.yearsExperience);
        }
        if (props.techFrontierPosition !== undefined && props.techFrontierPosition !== null) {
            props.control.setValue("techFrontierPosition", props.techFrontierPosition);
        }

        window.scrollTo(0, 0);
    }, [props.yearsExperience, props.techFrontierPosition, props.control]);


    return (
        <form component="fieldset" onSubmit={props.handleSubmit(props.customSubmit)} style={{overflowX: "hidden"}}>
            <Paper className={classes.paper} elevation={12} data-aos={"fade-up"}>
                <Grid container spacing={0} alignItems={"center"} justify={"center"}>
                    <div className={classes.techLine}>
                        <Grid item xs={12}>
                            <h3 className={classes.legendText} component="legend">
                                Experiencia en la línea tecnológica
                            </h3>
                        </Grid>
                        <Grid item xs={12} align={"center"} style={{margin: "20px"}}>
                            <Typography component={'div'} id="discrete-slider" gutterBottom>
                                <h6>
                                    ¿Cuál cree que es su posicionamiento o grado de capacidad en esta línea tecnológica
                                    en función de su proximidad a la frontera del conocimiento?
                                </h6>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <>
                                <Controller
                                    defaultValue={props.techFrontierPosition}
                                    render={(renderProps) => (
                                        <Slider
                                            {...renderProps}
                                            onChange={(_, value) => {
                                                renderProps.onChange(value);
                                            }}
                                            valueLabelDisplay="auto"
                                            max={3}
                                            min={-3}
                                            step={1}
                                            marks={marksTechFrontierPosition}
                                        />
                                    )}
                                    name={"techFrontierPosition"}
                                    control={props.control}
                                />
                            </>
                        </Grid>

                        <Grid item xs={12} align={"center"} style={{marginBottom: "50px"}}>
                            <Typography component={'div'} id="discrete-slider" gutterBottom>
                                <h6>
                                    Indique los años de experiencia con los que cuenta la empresa en la línea
                                    tecnológica.
                                </h6>
                            </Typography>
                        </Grid>
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            inputRef={props.register}
                            required
                            name="yearsExperience"
                            label="Años de experiencia"
                            defaultValue={props.yearsExperience}
                            InputLabelProps={{
                                shrink: Boolean(props.yearsExperience),  // Esto hace que el label siempre se mueva
                            }}
                        />
                        <div>
                            {props.errors.yearsExperience &&
                                <FormLabel className={classes.warmText}>
                                    El campo debe ser numérico
                                </FormLabel>}
                        </div>
                    </div>
                </Grid>
            </Paper>
            <hr className={classes.hrForm}></hr>
            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>

        </form>
    );
}

export default TechLineExperienceFormView;