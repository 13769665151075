export const timeToStartMapping = {
    threeOrLessMonths: "En menos de 3 meses",
    betweenFourAndTwelveMonths: "Entre 4 y 12 meses",
    moreThanAYear: "Dentro de un año o más",
};

// Mapeo de las claves a los valores (nombres de los plazos)
export const timeToStartKeys = Object.keys(timeToStartMapping);  // ["threeOrLessMonths", "betweenFourAndTwelveMonths", "moreThanAYear"]
export const timeToStartValues = Object.values(timeToStartMapping);  // ["En menos de 3 meses", "Entre 4 y 12 meses", "Dentro de un año o más"]

export const collaborationMapping = {
    remote: "Remoto",
    onSite: "Presencial",
    hybrid: "Híbrido",
    flexible: "Flexible",
};

// Mapeo de las claves a los valores (nombres de los tipos de colaboración)
export const collaborationKeys = Object.keys(collaborationMapping);  // ["remote", "onSite", "hybrid", "flexible"]
export const collaborationValues = Object.values(collaborationMapping);  // ["Remoto", "Presencial", "Híbrido", "Flexible"]

export const entityTypeMapping = {
    tractingCompany: "Empresa tractora dinamizadora del entorno o aceleradora de jóvenes empresas innovadoras",
    startUpCompany: "Start-up y/o spin-off de base tecnológica o innovadora",
    prefersConsortiums: "Empresa que desea colaborar en consorcios de I+D+i",
    centerId: "Centro de I+D público o privado",
    technologyInstitutes: "Institutos tecnológicos",
    europeanCenter: "Centro Europeo de Empresas Innovadoras o Cámara de Comercio",
    entityManagementBusinessArea: "Entidad de gestión de área empresarial",
    businessAssociation: "Asociación empresarial",
    ong: "Organizaciones no gubernamentales y otras organizaciones del tercer sector",
};

// Mapeo de las claves a los valores (nombres de las entidades)
export const entityTypeKeys = Object.keys(entityTypeMapping);  // ["tractingCompany", "startUpCompany", "prefersConsortiums", "centerId", "technologyInstitutes", "europeanCenter", "entityManagementBusinessArea", "businessAssociation", "ong"]
export const entityTypeValues = Object.values(entityTypeMapping);  // ["Empresa tractora dinamizadora del entorno...", "Start-up y/o spin-off de base tecnológica...", ...]

