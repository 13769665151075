import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import TechLineView from "../../../../views/userArea/companyCardComponentsViews/techLineView";
import TrackEvent from "../../../../tracking/EventTracking";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../../EnvironmentVars";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

function TechLine(props) {

    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const addAsset = (event, techLineId) => {
        history.push({
            pathname: '/stepperassetform',
            state: {techLineId: techLineId, company: props.company}
        })
        localStorage.setItem("techLineId", techLineId);
        localStorage.removeItem("assetCollaborationForm");
        localStorage.removeItem("assetGrlForm");
        localStorage.removeItem("assetForm");
        TrackEvent("start_form_asset", "Inicia formulario activo", "start_form_asset")
    }

    const addCapacity = (event, techLineId) => {
        history.push({
            pathname: '/steppercapacityform',
            state: {techLineId: techLineId, company: props.company}
        })
        localStorage.setItem("techLineId", techLineId);
        localStorage.removeItem("capacityForm");
        localStorage.removeItem("capacityGeneralForm");
        TrackEvent("start_form_capacity", "Accede formulario capacidad", "start_form_capacity")
    }

    /*function handleEditButton(event, techLine) {
        localStorage.removeItem("techLineForm");
        localStorage.removeItem("experienceForm");
        localStorage.removeItem("workersForm");
        let techLineForm = {
            generalData: {
                id: props.techLine.id,
                techLineId: techLine.techLineId,
                name: techLine.name,
                companyId: techLine.companyId,
                techLineApplicant: techLine.techLineApplicant
            },
            experienceData: {
                yearsExperience: techLine.yearsExperience,
                techFrontierPosition: techLine.techFrontierPosition
            },
            workersData: {
                workersAssigned: techLine.workersNum,
                workersHighValue: techLine.highValueWorkersNum,
                wantToStartSoonCollaboration: techLine.wantToStartSoonCollaboration,
                timeToStartCollaboration: techLine.timeToStartCollaboration,
                collaborationWillingness: techLine.collaborationWillingness,
                collaborationWillingnessPreference: techLine.collaborationWillingnessPreferenceTechLine
            }
        }

        history.push({
            pathname: '/steppertechlineform',
            state: {company: techLine.companyId, techLine: techLineForm}
        })
    }*/

    function handleEditButton(event) {
        localStorage.removeItem("techLineForm");
        localStorage.removeItem("experienceForm");
        localStorage.removeItem("workersForm");
    
        let techLineForm = {
            generalData: {
                id: props.techLine.id,
                techLineId: props.techLine.techLineId,
                name: props.techLine.name,
                companyId: props.techLine.companyId,
                techLineApplicant: props.techLine.techLineApplicant
            },
            experienceData: {
                yearsExperience: props.techLine.yearsExperience,
                techFrontierPosition: props.techLine.techFrontierPosition
            },
            workersData: {
                workersAssigned: props.techLine.workersNum,
                workersHighValue: props.techLine.highValueWorkersNum,
                wantToStartSoonCollaboration: props.techLine.wantToStartSoonCollaboration,
                timeToStartCollaboration: props.techLine.timeToStartCollaboration,
                collaborationWillingness: props.techLine.collaborationWillingness,
                collaborationWillingnessPreference: props.techLine.collaborationWillingnessPreferenceTechLine
            }
        };
    
        // Guardar en localStorage para asegurar que la otra pantalla lo recibe bien
        localStorage.setItem("techLineForm", JSON.stringify(techLineForm));
    
        history.push({
            pathname: '/steppertechlineform',
            state: { company: props.techLine.companyId, techLine: techLineForm }
        });
    }

    async function deleteAssetCompany(event, techLineId, assetcompanyId) {

        Alert.fire({
            text: "Esta acción no se puede deshacer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colorPrimary,
            cancelButtonColor: '#ee6123',
            iconColor: '#ee6123',
            confirmButtonText: '¡Sí, borra el activo!',
            cancelButtonText: '¡Espera!'
        }).then(async (result) => {
                setLoading(true);
                if (result.isConfirmed) {
                    let data = {};
                    const url = "assetscompany/" + assetcompanyId
                    await axios({
                        method: 'DELETE',
                        url,
                        data: data,
                    }).then(
                        res => {
                            let assetCompanylistWithoutErased = props.techLine.assetsCompany.filter(assetCompany => assetCompany.id !== assetcompanyId);
                            let techLinesWithAsset = props.techLines;
                            for (let i = 0; i < props.techLines.length; i++) {
                                if (techLinesWithAsset[i].id === techLineId) {
                                    techLinesWithAsset[i].assetsCompany = assetCompanylistWithoutErased;
                                }
                            }
                            props.setTechLines(techLinesWithAsset);
                        },
                        error => {
                            if (error.response.status === 400) {
                            }
                        }
                    ).catch(
                        error => {
                            console.error(error)
                        }
                    )

                    Alert.fire({
                            text: 'Se ha eliminado el activo',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                }
                setLoading(false);
            }
        )

    }

    async function deleteCapacityCompany(event, techLineId, capacityCompanyId) {

        Alert.fire({
            text: "Esta acción no se puede deshacer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colorPrimary,
            cancelButtonColor: '#ee6123',
            iconColor: '#ee6123',
            confirmButtonText: '¡Sí, borra la capacidad!',
            cancelButtonText: '¡Espera!'
        }).then(async (result) => {
                setLoading(true);
                if (result.isConfirmed) {
                    let data = {};
                    const url = "capacitiescompany/" + capacityCompanyId
                    await axios({
                        method: 'DELETE',
                        url,
                        data: data,
                    }).then(
                        res => {
                            let capacityCompanylistWithoutErased = props.techLine.capacitiesCompany.filter(capacityCompany => capacityCompany.id !== capacityCompanyId);
                            let techLinesWithCapacity = props.techLines;
                            for (let i = 0; i < props.techLines.length; i++) {
                                if (techLinesWithCapacity[i].id === techLineId) {
                                    techLinesWithCapacity[i].capacitiesCompany = capacityCompanylistWithoutErased;
                                }
                            }
                            props.setTechLines(techLinesWithCapacity);
                        },
                        error => {
                            if (error.response.status === 400) {
                            }
                        }
                    ).catch(
                        error => {
                            console.error(error)
                        }
                    )

                    Alert.fire({
                            text: 'Se ha eliminado la capacidad',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                }
                setLoading(false);
            }
        )

    }

    return (
        loading ?
            <CircularProgress color="primary"/>
            :
            <TechLineView techLine={props.techLine}
                          addAsset={addAsset}
                          addCapacity={addCapacity}
                          deleteTechLine={props.deleteTechLine}
                          handleEditButton={handleEditButton}
                          deleteAssetCompany={deleteAssetCompany}
                          deleteCapacityCompany={deleteCapacityCompany}
                          numberOfTechline={props.numberOfTechLine}
            />
    );
}

export default TechLine;